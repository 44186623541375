@import url("../../styles/base.module.css");

.dots {
  display: flex;
  align-items: center;
  gap: 12px;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 12px;
  background-color: var(--color-lightwhite);
  animation-duration: 1s;
  animation-iteration-count: infinite;
  transition: background-color .5s;
}

.dot:nth-child(1) {
  animation-delay: .0s;
  animation-name: purpleBg;
}

.dot:nth-child(2) {
  animation-delay: .33s;
  animation-name: orangeBg;
}

.dot:nth-child(3) {
  animation-delay: .66s;
  animation-name: greenBg;
}

@keyframes purpleBg {
  50% {
    background-color: var(--color-purple);
  }
}

@keyframes orangeBg {
  50% {
    background-color: var(--color-orange);
  }
}

@keyframes greenBg {
  50% {
    background-color: var(--color-green);
  }
}