@import url("./base.module.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Manrope';
}

body {
  max-width: 100vw;
}

.app {
  color: var(--color-grey);
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  min-height: 100svh;
  opacity: 0;
  animation: op .6s forwards;
}

.onfido-sdk-ui-ActiveVideo-Header-header svg {
  display: none;
}

.onfido-sdk-ui-ActiveVideo-Header-header h3.onfido-sdk-ui-ActiveVideo-Header-title {
  margin: 12px 0;
}

div.onfido-sdk-ui-Theme-scrollableContent[data-page-id="FaceNotDetected"] ul.onfido-sdk-ui-ActiveVideo-FaceNotDetected-list {
  gap: 12px;
}

@keyframes op {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
